.color-picker__swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;

  .color-picker__color {
    height: 14px;
    border-radius: 2px;
  }
}

.color-picker__popup {
  position: relative;

  .color-picker__cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sketch-picker {
    margin: 0.5rem auto;
  }
}
