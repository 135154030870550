.logo-container {
  display: grid;
  justify-items: center;

  svg {
    max-width: 17rem;
    fill: currentColor;
    color: var(--color-primary-500);
  }

  svg .letter-x-animation,
  svg .letter-x {
    fill: transparent;
    stroke-width: 1px;
  }

  svg .letter-x-animation {
    stroke-dasharray: 100;
    stroke: currentColor;
  }

  svg .letter-x {
    stroke: #b3bdc7;
  }

  &.animated .letter-x-animation {
    animation: dash 7s cubic-bezier(0.39, 0.58, 0.57, 1) infinite;
  }
  @keyframes dash {
    to {
      stroke-dashoffset: 1000;
    }
  }
}
