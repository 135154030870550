.workspace-grid {
  grid-template-columns: repeat(100, 1fr);
  grid-template-rows: repeat(100, 1fr);
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

  .grid-border {
    &::after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      border: 0 solid #00000094;
      pointer-events: none;
      z-index: 2;
    }

    &.top::after {
      border-top-width: 1px;
    }

    &.bottom::after {
      border-bottom-width: 1px;
    }

    &.left::after {
      border-left-width: 1px;
    }

    &.right::after {
      border-right-width: 1px;
    }
  }

  .highlighted::before {
    content: "";
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border: 5px solid var(--color-accent-2-500);
    z-index: 1;
  }

  .grid__cell {
    position: relative;
    container-type: inline-size;
    container-name: metx-tool-container;
  }

  .grid__cell.grid__cell--empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .grid__cell.grid__cell__plot {
    // Background color for image export
    background-color: white;
    display: grid;
  }

  .grid__cell.grid__cell__map {
    display: grid;
  }

  .grid__cell.grid__cell__table {
    display: grid;

    &--bottom {
      padding-bottom: 60px;

      & > div {
        border-bottom: 1px solid var(--color-gray-900);
      }
    }
  }

  .grid__cell.disabled__cell {
    opacity: 0.5;
  }

  .extra-padding-top {
    padding-top: 60px;
    transition: ease-in-out 0.2s;
  }

  // Padding to give a plot at the top a margin so the header doesn't cover it.
  .extra-padding-bottom {
    padding-bottom: 60px;
    transition: ease-in-out 0.2s;
  }

  .padding-bottom {
    padding-bottom: 10px;
  }

  .grid-arrangement-overlay {
    position: absolute;
    display: table;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  .grid-arrangement-icon {
    font-size: 120px;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
}
