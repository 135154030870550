.backdrop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  z-index: 100;

  display: flex; //TODO think of better solution
  justify-content: center;
  align-items: center;
}

.popup-window {
  width: max-content;
  display: flex;
  flex-direction: column;
  margin: 1em;
}

.title-header {
  font-size: large;
  font-weight: 700;
}

.secondary-msgs {
  display: flex;
  flex-direction: column;
}

.secondary-msgs div {
  margin: 0.5em 0 0.5em 0;
}
