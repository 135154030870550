.chip {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 6rem;
  height: 1.4rem;
  font-size: 12px;
  border-radius: 12px;
  padding: 0.2rem 0.2rem 0.2rem 0.5rem;

  gap: 0.2rem;

  .delete-action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    border: 0;
    background: rgba(0, 0, 0, 0.2);

    cursor: pointer;

    .icon {
      font-size: 12px;
    }
  }

  &.quantile {
    background: var(--color-accent-2-500);
  }

  &.member {
    background: var(--color-accent-1-500);
  }

  &.additional {
    background: var(--color-primary-500);
    color: var(--text-color-primary-500);

    .delete-action {
      background: rgba(255, 255, 255, 0.2);

      .icon {
        color: var(--text-color-primary-500);
      }
    }
  }
}
