.footer-panel {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 1rem;
  border-radius: 50vh;

  // TODO Replace #FFFFFF with color from zeus
  background-color: rgba(#FFFFFF, .9);
  border: 1px solid var(--mm-gray-1);
}
