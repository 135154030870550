@import "~modern-normalize/modern-normalize.css";

* {
  box-sizing: border-box;
}

@import "variables";

@import "shadow";
@import "branding";
@import "logo";
@import "background";
@import "actionable";
@import "form";
@import "fonts";

@import "layout/grid";
@import "layout/text";
@import "layout/tier";
@import "layout/overlay";

// TODO: dialog css should be placed next to higher-level dialog component
@import "components/dialog";
@import "components/float";
@import "components/tag";
@import "components/listing";
@import "components/form";
@import "colors";

// TODO: move somewhere `layout-*` classes somewhere else
// horizontally and vertically center all children. Use to center a dialog etc.
.layout--center-children {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
}

html,
body,
.cover-window {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--font-stack);
}

// toastify customizeable variables
:root {
  --toastify-toast-width: fit-content !important;
  --toastify-font-family: var(--font-stack) !important;
  --toastify-toast-background: var(--color-gray-500) !important;
  --toastify-text-color-light: var(--text-color-gray-50) !important;

  --toastify-color-info: #08647e !important;
  --toastify-color-success: #129a7e !important;
  --toastify-color-warning: #fff374 !important;
  --toastify-color-error: #e74c3c;
}

// remove the outline shown around mapbox canvas after
// mouse interaction
canvas {
  outline: 0 !important;
}

.mapbox-tooltip {
  display: flex;
  flex-direction: column;

  gap: 0.3rem;
  font-size: 0.625rem;
  color: black;

  margin: 0.4rem 0.56rem;

  &__title_header {
    display: flex;
    justify-content: space-between;

    & > div:first-child {
      flex: 1;
    }
  }

  &__title {
    background-color: #1150a0;
    font-weight: bold;
    color: white;

    padding: 0 0.5rem;

    &--sticky {
      position: sticky;
      top: 0
    }
  }

  &__content {
    padding: 0 0.5rem;
  }

  &__property {
    &__name {
      color: #7777cc;
      font-weight: bold;
    }
  }
}

.mapbox-tooltip--scrollable {
    max-height: 400px;
    overflow-y: scroll;
}

.mapbox-tooltip-table {
  color: var(--color-primary-500);
  border-collapse: collapse;
  border-spacing: 0;

  tr > td {
    padding: 0;
    background-color: rgba($mm-gray-1, 0.2);
    padding: 0.1rem 0.5rem;
  }

  tr:nth-child(odd) > td {
    background-color: rgba($mm-gray-1, 0.5);
  }

  .issue-date-time {
    vertical-align: top;
    white-space: nowrap;
    font-weight: 600;
  }

  &__ {
    &column-name {
      font-weight: bold;
      padding-right: 0.8rem;
    }
  }
}
