.toggle-legend {
  min-height: 36px;
  min-width: auto;
  padding: 2px 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--color-gray-100);

  cursor: pointer;

  &__checkbox {
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;

    &:not(:checked) ~ label {
      opacity: 0.1;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    text-orientation: mixed;
    writing-mode: vertical-rl;

    cursor: pointer;
  }

  &:hover {
    background: var(--color-gray-200);
  }
}
