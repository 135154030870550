.legend-list {
  padding-inline-start: 0;
}

.legend-list li {
  display: flex;
  align-items: center;
}

.legend-list li div {
  margin: 1px 5px 1px 1px;
}

.legend-item-box {
  border-width: 3px;
  border-style: solid;
  padding: 3px;
}

.legend-vector-container {
  padding-top: 2rem;
}

.flex-start-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sigmet-legend {
  display: grid;

  gap: 0.4rem;
  margin: 0.5rem 2rem;

  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto auto;
    gap: 0.4rem;
  }

  &__box {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    border-radius: 0.4rem;
    padding: 0.6rem;
    height: 4rem;

    &__title {
      flex: 12;
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: bold;
    }

    &__caption {
      font-size: 0.7rem;
    }
  }
}
