// Structured listings for navigation menus, search predictions, ...
.listing {
  display: grid;
  & > a:link,
  &--actionable > * {
    @extend .actionable;
  }

  & > * {
    padding: 1rem 2rem;
    // prevent grid blowout
    overflow: hidden;
  }
}
