@import "../../../style/colors";

.mapbox-popover-table {
  font-family: var(--font-stack);

  .mapboxgl-popup-close-button {
    top: -0.5rem;
    right: -0.5rem;
    width: 1.4rem;
    height: 1.4rem;
    font-size: 1rem;
    border-radius: 0.7rem;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background: #f3f3f3;
    }
  }

  .mapboxgl-popup-content {
    padding: 0;
    border-radius: 0.5rem;
  }

  .mapbox-tooltip {
    padding: 0;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .table {
    display: grid;
    grid-template-columns: auto 1fr; /* Define columns for the table */

    font-size: 0.75rem;
    white-space: nowrap;

    max-height: 400px;
    overflow-y: scroll;

    .header {
      display: contents;
      grid-template-columns: subgrid;

      font-weight: 600;

      .column {
        position: sticky;
        top: 0;

        padding: 0.3rem 0.5rem;
        background-color: var(--mm-gray-2);

        &:not(:last-child) {
          border-right: 1px solid var(--mm-gray-1);
        }
      }
    }

    .body {
      display: contents;
      grid-template-columns: subgrid;

      .column {
        padding: 0.3rem 0.5rem;

        &:not(:last-child) {
          border-right: 1px solid var(--mm-gray-2);
        }
      }

      .row {
        display: contents;

        &:hover {
          .column {
            background-color: rgba($mm-gray-2, 0.5);
          }
        }

        &:not(:last-child) {
          .column {
            border-bottom: 1px solid var(--mm-gray-2);
          }
        }
      }
    }
  }
}