.tool-dropdown {
  position: relative;
  user-select: none;

  white-space: nowrap;
}

.toggle-label {
  position: relative;
  height: 100%;

  display: grid;
  grid-gap: 3px;
  padding: 0 16px;
  align-content: center;

  font-size: 14px;
  font-weight: 500;
  text-align: center;

  background-color: var(--color-gray-100);
  color: var(--text-color-gray-100);

  &__dropdown-icon {
    position: absolute;
    top: 80%;
    width: 100%;

    font-size: 28px;

    text-align: center;
  }

  &:hover {
    background-color: var(--color-gray-200);
    color: var(--text-color-gray-200);
  }

  &:after {
    content: "arrow_drop_down";

    position: absolute;
    top: 82%;
    width: 100%;
    text-align: center;

    font-family: "Material Icons";
    font-size: 22px;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
  }

  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.illustration {
  width: 4rem;
  margin-top: 10px;
  justify-self: center;
}

.toggle {
  display: none;
}

.menu-items {
  display: none;

  position: absolute;
  flex-direction: column;
  top: 100%;
  width: 100%;
  z-index: 1;

  border-top: 1px solid var(--color-gray-900);
  border-bottom: 1px solid var(--color-gray-900);

  button {
    border: 0;
    padding: 6px 6px;
    font-size: 14px;

    background-color: var(--color-gray-100);
    color: var(--text-color-gray-100);

    cursor: pointer;

    &:hover {
      background-color: var(--color-gray-200);
      color: var(--text-color-gray-200);
    }
  }
}

.toggle:checked ~ .menu-items {
  display: flex;
}

.toggle:checked ~ .toggle-label::after {
  content: "arrow_drop_up";
}
