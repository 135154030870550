@use "~ag-grid-community/styles" as ag;

@include ag.grid-styles(
  (
    theme: alpine,
    --ag-font-family: inherit,
    --ag-border-color: #d1dfdb,
    --ag-row-hover-color: #4d72861c,
    --ag-row-border-color: var(--color-gray-300),
    --ag-header-background-color: #e5edea,
    --ag-cell-horizontal-border: 1px solid var(--color-gray-300),
    --ag-grid-size: 0.3rem,
    --ag-list-item-height: 1.25rem,
    --ag-cell-horizontal-padding: 0.5rem,
  )
);

// Fix for column drag & drop box
.ag-unselectable {
  display: flex;
}
