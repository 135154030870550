.ens-selection-panel {
  gap: 0.1rem;
  display: grid;
  pointer-events: all;

  input::placeholder {
    text-transform: capitalize;
  }
}

.chips-container {
  width: 100%;
  height: 100%;

  display: flex;
  white-space: unset;
  flex-wrap: wrap;

  padding: 0.2rem 0.2rem;
  gap: 0.2rem;

  cursor: auto;

  &:focus {
    border-color: inherit;
  }
}

.ens-dialog {
  z-index: 1;
}
