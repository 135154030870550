.page--login {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;

  .locale-picker {
    align-self: flex-end;
  }
}
