@import "~mapbox-gl/dist/mapbox-gl.css";

.mapboxgl-ctrl-bottom-right {
  z-index: 0;

  .mapboxgl-ctrl {
    margin: 0 8px 10px 0;
  }
}

.grid__cell__bottom {
  .mapboxgl-ctrl-bottom-right {
    transition: bottom 200ms ease-in;
  }

  &.footer-visible .mapboxgl-ctrl-bottom-right {
    bottom: 50px;
  }
}

// Hide Mapbox logo for Scale Control
.mapboxgl-ctrl-logo {
  display: none !important;
}

.map-container {
  display: grid;
}

.upscaling {
  width: 100% !important;
  height: 100% !important;
}

.upscaling--pixelated {
  image-rendering: optimizeSpeed; /* Older versions of FF          */
  image-rendering: -moz-crisp-edges; /* FF 6.0+                       */
  image-rendering: -webkit-optimize-contrast; /* Safari                        */
  image-rendering: -o-crisp-edges; /* OS X & Windows Opera (12.02+) */
  image-rendering: pixelated; /* Awesome future-browsers       */
  -ms-interpolation-mode: nearest-neighbor; /* IE                            */
}
