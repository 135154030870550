.actionable {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--color-gray-100);
  }
  &.active {
    background: var(--color-primary-500);
    color: var(--text-color-primary-500);
  }
}
