@import "../../../style/shadow";

.tab-switcher {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;

  &__component {
    width: fit-content;
    max-width: calc(100% - 56px);
  }
}

.tab-list {
  margin-left: 8px;
}

