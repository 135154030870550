.expansion-panel__header {
  align-items: center;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  padding: 0.2rem 0;

  &__ {
    &layer-info {
      user-select: none;
      display: grid;
      align-items: center;
      grid-template-columns: auto 1fr;

      font-weight: 600;
      font-size: 0.9rem;

      &__sub {
        font-size: 0.86rem;
      }
    }

    &right-control {
      display: flex;
      justify-content: flex-end;
      visibility: hidden;
      opacity: 0;
      padding-right: 6px;
      grid-gap: 6px;

      .arrow-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .button {
          padding: 0 2px;
          min-height: 18px;

          .material-icons {
            font-size: 18px;
          }
        }
      }

      // TODO: this should be properties of the Button component
      // could be called `minimizeSize`
      .button {
        padding: 2px 2px;
        min-width: auto;
      }
    }
  }
}

.expansion-panel__header:hover,
.expansion-panel--expanded .expansion-panel__header {
  .expansion-panel__header__right-control {
    visibility: visible;
    opacity: 1;
  }
}

.expansion-panel__header.hidden {
  .layer-label {
    opacity: 0.2;
  }
}

.expansion-panel__content {
  padding-bottom: 6px;
  margin: 6px 6px 0px 6px;
}

.expansion-panel__chevron {
  display: grid;
  align-items: center;
  justify-content: center;
}
