// A stack of panels with a lot of margin. Usually used on landing pages.
// Each tier covers most of the screen -- so its similar to a slideshow.
//
// For a well done tiered layout, check out: https://paravelinc.com/
//
// To arange elements within a tear use the `.grid` class. To visually
// break out of a tier, use classes from `background.scss`.
.tier {
  min-height: 80vh;
}
