@use "~ag-grid-community/styles" as ag;

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@include ag.grid-styles(
  (
    theme: alpine,
    --ag-font-family: inherit,
    --ag-borders: none,
    // We can't use variable here, as ag-grid add opacity under the hood
    // and that breaks scss variables :(
    --ag-range-selection-border-color: #4d7286,
    --ag-row-hover-color: #4d72861c,
    --ag-row-border-color: var(--color-gray-300),
    --ag-header-background-color: var(--mm-gray-2),
    --ag-cell-horizontal-border: 1px solid var(--color-gray-300),
    --ag-header-cell-moving-background-color: var(--color-gray-900),
    --ag-header-column-resize-handle-color: var(--mm-gray-3),
    --ag-header-column-resize-handle-height: 75%,
    --ag-header-column-resize-handle-width: 1px,
    --ag-font-size: 12px,
  )
);

.title-header {
  div {
    text-align: center;
  }

  margin: 20px 0 20px;
}

.table-location {
  font-size: 1rem;
}

.weather-grid-wrapper {
  .ag-theme-alpine {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: min-content auto;

    height: 100%;

    .ag-header-cell {
      line-height: 16px !important;
      font-weight: 400;
      font-size: 12px !important;
      @include flex-center;
    }

    .ag-row {
      line-height: 16px !important;
    }
  }
}

// Fix for column drag & drop box
.ag-unselectable {
  display: flex;
}

.ag-header-cell-resize::after {
  width: 1px!important;
  height: 75%!important;
  left: calc(50% - 1px)!important;
  top: calc(50% - 75% * 0.5)!important;
}

.ag-header-cell-resize:hover::after, .ag-header-cell-resize:active::after {
  background-color: var(--mm-gray-4);
}

.ag-header-cell-resize::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
  height: 4px;
  background-size: cover;
  background: url("./img/resize-arrow.svg") no-repeat;
}

.ag-header-cell-resize:hover::before, .ag-header-cell-resize:active::before {
  filter: invert(63%) sepia(24%) saturate(220%) hue-rotate(109deg) brightness(92%) contrast(85%);
}

.ag-header-group-cell-with-group:not(.ag-column-first) {
  background-color: var(--mm-gray-1);
  &:hover {
    background-color: var(--mm-gray-1)!important;
  }
}

.ag-header-cell-text, .ag-header-group-text {
  font-weight: 400!important;
}

.ag-header-cell-label {
  @include flex-center;
}

.ag-cell {
  line-height: 16px !important;
  padding-top: 0;
  font-weight: 400;
  font-size: 12px !important;
  display: flex!important;
  justify-content: center;
  align-items: center;
  & > div {
    @include flex-center;
  }
}

.ag-column-first .material-icons {
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  cursor: pointer;
}

.ag-column-first span:not(.material-icons):not(.ag-header-cell-text) {
  display: none;
}
