.scrollable {
  overflow-y: auto;
  // every overlay window height should only be as height as the browser Viewport height
  max-height: calc(100vh - 30px); // minus some space
}

.drag-hidden {
  display: none;
  pointer-events: none;
}

[data-window-kind] {
  // TODO: this animation is currently run after a drag and drop, which is not desirable. Fix and reenable
  //animation: fadeIn 0.25s;
  //animation-fill-mode: forwards;
  width: var(--window-width);
}

[data-desktop-id] {
  position: relative;
}

.window {
  --header-height: 2.625rem;
  position: relative;
  overflow-y: auto;

  &.resizable {
    overflow: visible;
  }

  &-body {
    position: relative;

    &.resizable {
      position: absolute;
    }

    width: 100%;
    height: calc(100% - var(--header-height));
    top: 0;
    left: 0;
  }
}

.window-resize-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  pointer-events: none;

  &.active {
    border: 1px solid var(--color-gray-500);
  }

  &__handle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 14px;
    height: 14px;
    cursor: nwse-resize;
    touch-action: none;
    pointer-events: all;
    z-index: 21;

    & svg {
      position: absolute;
      width: 100%;
      height: 100%;

      & path {
        fill: var(--color-gray-700);
      }
    }
  }
}

.drag-preview-container {
  // Note: actual position is then computed using 3d transforms.
  position: absolute;
  top: 0;
  left: 0;

  z-index: 10000;

  .float {
    pointer-events: none !important;
    // disable all fade-in, fade-out effects etc, that might be enabled
    // if the preview renderer is identical to the window itself.
    transition: none !important;
    animation: none !important;
  }

  will-change: transform;
}

.drag-preview-grid {
  width: 20rem;
  height: 20rem;
  border: #0a0a0a dashed 1px;
  text-align: center;
}

.window--is-dragging {
  // hide the window without removing it from the dom. This is required as an IE fallback,
  // because IE will ignore our custom "empty image" drag preview.
  height: 0 !important;
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0 !important;

  // this trick might fail if the children have an explicit opacity.
  // note: we cannot use visibility, display or overflow since this will cancel
  // the drag and drop operation.
  * {
    opacity: 0 !important;
  }

  // Doesn't work in combination with `pointer-events: none` that is mandatory by react-dnd
  // Instead we need to change cursor with JS :(
  // Leaving CSS here, as non working example
  // .window-titlebar {
  // cursor: grabbing !important;
  // }
}

.window-manager__desktop--is-dragging {
  // TODO: a drop target with `pointer-events: none` will not fire any events.
  //
  // A simpler workaround would be to restructure the application from
  // ```
  // <zlayers>
  //   <Grid of Maps and Plots>
  //   <DesktopProvider><DesktopContent /></DesktopProvider>
  // </zlayers>
  // ```
  // to something like
  // ```
  // <DesktopProvider>
  //    <zlayers>
  //      <Grid of Maps and Plots>
  //      <DesktopContent />
  //    </zlayers>
  // </DesktopProvider>
  // ```
  //
  // But that would imply that either:
  // (a) all possible overlays use the Desktop paradigm.
  // (b) map rendering is deferred until the overlay is loaded
  //
  // Needs discussion.
  // Note that this also lets use render some cool global effects during drag, e.g.
  // bluring or fading the map etc.
  pointer-events: all;

  // set here instead of `.window-titlebar` to make sure the dragging cursor is used while
  // dragging, even if the window preview renderer cannot keep up with the cursor
  cursor: drag !important;
}

.window-manager__desktop--contains-visible-modal {
  .window-manager__track[data-window-track-id="Modal_"],
  .window-manager__track[data-window-track-id="None_"] {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    display: grid;

    // true center alignment (align-items: center) is problematic for interactive content, since
    // the window will move if the content height changes.
    // Most people would also prefer centered content to be slightly translated to the top.
    padding-top: MIN(8rem, 20%);
    justify-content: center;
    align-items: flex-start;

    // disable click events to everything else
    // TODO: keyboard events are still possible, e.g. pressing tab a lot
    // to focus things behind the modal
    z-index: 999;
    pointer-events: all;
  }
}

.window-manager__track {
  display: flex;
  gap: var(--margin-to-screen-borders);

  // tracks are vertical, top-aligned by default
  flex-direction: column;

  &[data-window-track-id="Right_"] {
    position: absolute;
    right: var(--margin-to-screen-borders);
    top: var(--margin-to-screen-borders);
    bottom: var(--margin-to-screen-borders);
  }

  &[data-window-track-id="Left_"] {
    position: absolute;
    left: var(--margin-to-screen-borders);
    top: var(--margin-to-screen-borders);
    bottom: var(--margin-to-screen-borders);
  }

  &[data-window-track-id="Bottom_"] {
    // track is horizontal
    flex-direction: row;
    // center windows in this track vertically
    justify-content: center;
    // windows in this track should only use the height they need,
    // at the same time, horizontally align them
    align-items: center;

    position: absolute;
    left: var(--margin-to-screen-borders);
    right: var(--margin-to-screen-borders);
    bottom: var(--margin-to-screen-borders);
  }

  // TODO Implement colors from design system when they are ready
  &__dropzone {
    font-family: var(--font-stack);
    // has to have a greater z-index than the desktop dropzone (which is 0)
    // and a smaller z-index than the dragged window (which is 10000)
    z-index: 1;

    // minimal height to allow for dropping
    min-height: 1.875rem;
    // padding left and right
    padding: 0.5rem 0.5rem;
    // some visual styling to make the dropzone visibile
    border: 2px dashed #1c5446;
    border-radius: 1rem;
    background: #f8edcc4d;
    display: grid;
    justify-content: center;
    align-items: center;
    color: #1c5446;
    font-size: 0.875rem;
    text-transform: uppercase;
    width: var(--window-width);

    &--hovered {
      border: 2px dashed var(--color-app-state--ok-500);
      color: var(--color-app-state--ok-500);
      background: #369f824d;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0.99;
  }
  100% {
    opacity: 1;
  }
}

.window-titlebar {
  display: grid;
  position: relative;
  align-items: center;
  grid-template-columns: min-content 1fr min-content;

  background: transparent;

  height: var(--header-height);
  padding: 0 0.75rem;

  .active-zone {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    user-select: none;
  }

  &--draggable {
    .active-zone {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }
  }

  &__ {
    &icon {
      color: var(--color-gray-500);
      font-size: 1.375rem;
    }

    &title {
      text-align: center;
      font-size: 0.875rem;
      color: var(--text-color-gray-500);
      letter-spacing: 0.2px;
    }

    &actions {
      display: grid;
      grid-auto-flow: column;
      gap: 2px;
    }

    &action {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.625rem;

      width: 1.25rem;
      height: 1.25rem;

      border: 0;

      cursor: pointer;
      // this background value is very similar to the gray shade `var(--color-gray-100)`, but implemented
      // through opacity to make it work on dark windows
      background: #8e9db24f;
      color: var(--text-color-gray-100);
      user-select: none;

      & > span {
        font-size: 0.875rem;
        font-weight: bold;
      }

      &:hover {
        background: var(--color-gray-300);
        color: var(--text-color-gray-300);
      }

      &--close:hover {
        background: var(--color-app-state--error-500);
        color: var(--text-color-app-state--error-500);
      }
    }
  }

  * {
    user-select: none;
  }
}
