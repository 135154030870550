.layer-label__kindName--parameter {
  font-family: var(--font-stack-mono);
}

.layer-tag__container {
  display: grid;
  grid-gap: 0.2rem;
  grid-auto-flow: column;
}

.layer-label__kindName {
  // Cut off a too long parameter name and add "..." at the end
  overflow: hidden;
  text-overflow: ellipsis;
}

.layer-label {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 0.4rem;
  align-items: center;
}
