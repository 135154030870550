/**
 * Library for stacking elements.
 *
 * Example: The three texts "bottom", "middle" and "top" will be rendered on top of each other
 *
 * ```
 * <div class="overlay">
 *     <div class="overlay__layer">bottom</div>
 *     <div class="overlay__layer">middle</div>
 *      <div class="overlay__layer">top</div>
 * </div>
 * ```
 */

.overlay {
  position: relative;

  &__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
