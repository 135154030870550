.poi-panel {
  display: grid;
  gap: 0.6rem;

  &__ {
    &action-bar {
      display: flex;
      justify-content: flex-end;
    }

    &footer {
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      font-size: 0.8rem;
    }
  }
}

.poi-panel-grid {
  height: 200px;
}

.poi-column {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__ {
    &edit-input {
      position: absolute;
    }

    &editable-area {
      flex: 1;
    }
  }
}

.header-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  border: 0;

  background-color: transparent;

  &:hover {
    background-color: var(--color-gray-900);
  }

  & > span {
    font-size: 1rem;
  }
}
