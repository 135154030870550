/*
    DM Sans woff2 file was created by
    1. Donwload the font files from https://fonts.google.com/specimen/DM+Sans
    2. Converted the "DMSans-Regular.ttf" to woff2 using an online tool.
        - TFF to Woff2 converter: https://cloudconvert.com/ttf-to-woff2
*/
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 100;
  src: url(./fonts/DMSans-Thin.woff2) format("woff2");
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 200;
  src: url(./fonts/DMSans-ExtraLight.woff2) format("woff2");
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/DMSans-Light.woff2) format("woff2");
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/DMSans-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/DMSans-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  src: url(./fonts/DMSans-SemiBold.woff2) format("woff2");
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/DMSans-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 800;
  src: url(./fonts/DMSans-ExtraBold.woff2) format("woff2");
}
