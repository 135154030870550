.invalid-session-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 99999;
  pointer-events: none;

  &__notification {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 999;

    pointer-events: all;

    & > div {
      width: 60%;
      height: 10%;
      padding: 1rem;
      border-radius: 0.5rem;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;

      & .icon {
        font-size: 2rem;
        margin-right: 0.5rem;
        color: var(--color-app-state--error-700);
      }
    }

    a {
      color: var(--color-primary-500);
    }
  }
}

.invalid-session {
  pointer-events: none;
  filter: blur(10px);

  * {
    pointer-events: none !important;
  }
}
