.page--workspace {
  .gui-overlay {
    pointer-events: none;
    z-index: 3;
  }

  .enable-user-interaction {
    pointer-events: all;
  }
}
