/**
 * Higher-level component styles for overlays.
 */

.float {
  background: white;
  @extend .shadow-lg;

  &-- {
    &box {
      padding: 0;
    }

    &speech-bubble-left {
      margin-left: 12px;

      &:before {
        content: "";
        // draw a triangle
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-right-width: 12px;
        border-left: 0;
        border-right-color: white;
        display: block;

        // position triangle
        position: absolute;
        left: -11px;
        top: 0.95rem;
      }
    }
  }
}

.float-root {
  position: relative;

  &-- {
    // attach object to the right-side of the float root
    &right {
      position: absolute;
      top: 0;
      left: 100%;
    }
  }
}

.box-section {
  border-bottom: 2px solid var(--color-gray-200);
  width: 100%;
  padding: 1rem 0;

  display: grid;
  grid-template-columns: 2rem auto 2rem;

  & > * {
    grid-column: 2;
  }

  &:last-child {
    border-bottom: 0;
  }

  &.small {
    padding: 0.2rem 0;
  }

  &__title {
    padding: 0;
    padding-bottom: 0.4rem;
    margin: 0;
    text-align: center;

    // format as small caps
    font-size: 14px;
    color: var(--color-gray-700);
    letter-spacing: 0.2px;
    font-variant-ligatures: none;
  }

  &-- {
    // apply this class to a direct child to remove the left and right padding
    &breakout {
      grid-column: 1 / 4;
      width: 100%;
      max-width: var(--text-breakout-max-width);
      justify-self: center;
    }

    &flat {
      grid-template-columns: 0.5rem auto 0.5rem;
    }
  }
}
