@import "colors";

$font-stack: "DM Sans", sans-serif;
$font-stack-mono: "IBM Plex Mono", "monospace";

$color-primary-50: #e0e7ea;
$text-color-primary-50: black;
$color-primary-100: #b3c3cb;
$text-color-primary-100: black;
$color-primary-200: #809ba9;
$text-color-primary-200: black;
$color-primary-300: #4d7286;
$text-color-primary-300: white;
$color-primary-400: #26546c;
$text-color-primary-400: white;
$color-primary-500: #003652;
$text-color-primary-500: white;
$color-primary-600: #00304b;
$text-color-primary-600: white;
$color-primary-700: #002941;
$text-color-primary-700: white;
$color-primary-800: #002238;
$text-color-primary-800: white;
$color-primary-900: #001628;
$text-color-primary-900: white;
$color-primary-A100: #63a5ff;
$text-color-primary-A100: black;
$color-primary-A200: #3087ff;
$text-color-primary-A200: white;
$color-primary-A400: #006afc;
$text-color-primary-A400: white;
$color-primary-A700: #0060e2;
$text-color-primary-A700: white;

$color-secondary-50: #e1ecf0;
$text-color-secondary-50: black;
$color-secondary-100: #b5d1d8;
$text-color-secondary-100: black;
$color-secondary-200: #84b2bf;
$text-color-secondary-200: black;
$color-secondary-300: #5293a5;
$text-color-secondary-300: black;
$color-secondary-400: #2d7b91;
$text-color-secondary-400: white;
$color-secondary-500: #08647e;
$text-color-secondary-500: white;
$color-secondary-600: #075c76;
$text-color-secondary-600: white;
$color-secondary-700: #06526b;
$text-color-secondary-700: white;
$color-secondary-800: #044861;
$text-color-secondary-800: white;
$color-secondary-900: #02364e;
$text-color-secondary-900: white;
$color-secondary-A100: #81d0ff;
$text-color-secondary-A100: black;
$color-secondary-A200: #4ebdff;
$text-color-secondary-A200: black;
$color-secondary-A400: #1baaff;
$text-color-secondary-A400: black;
$color-secondary-A700: #02a1ff;
$text-color-secondary-A700: white;

$color-accent-1-50: #fbfff0;
$text-color-accent-1-50: black;
$color-accent-1-100: #f5feda;
$text-color-accent-1-100: black;
$color-accent-1-200: #effec1;
$text-color-accent-1-200: black;
$color-accent-1-300: #e8fea8;
$text-color-accent-1-300: black;
$color-accent-1-400: #e3fd96;
$text-color-accent-1-400: black;
$color-accent-1-500: #defd83;
$text-color-accent-1-500: black;
$color-accent-1-600: #dafd7b;
$text-color-accent-1-600: black;
$color-accent-1-700: #d5fc70;
$text-color-accent-1-700: black;
$color-accent-1-800: #d1fc66;
$text-color-accent-1-800: black;
$color-accent-1-900: #c8fc53;
$text-color-accent-1-900: black;
$color-accent-1-A100: #ffffff;
$text-color-accent-1-A100: black;
$color-accent-1-A200: #ffffff;
$text-color-accent-1-A200: black;
$color-accent-1-A400: #fcfff5;
$text-color-accent-1-A400: black;
$color-accent-1-A700: #f4ffdb;
$text-color-accent-1-A700: black;

$color-accent-2-50: #e8fbf9;
$text-color-accent-2-50: black;
$color-accent-2-100: #c6f6f1;
$text-color-accent-2-100: black;
$color-accent-2-200: #a0f0e8;
$text-color-accent-2-200: black;
$color-accent-2-300: #79e9de;
$text-color-accent-2-300: black;
$color-accent-2-400: #5de5d7;
$text-color-accent-2-400: black;
$color-accent-2-500: #40e0d0;
$text-color-accent-2-500: black;
$color-accent-2-600: #3adccb;
$text-color-accent-2-600: black;
$color-accent-2-700: #32d8c4;
$text-color-accent-2-700: black;
$color-accent-2-800: #2ad3be;
$text-color-accent-2-800: black;
$color-accent-2-900: #1ccbb3;
$text-color-accent-2-900: black;
$color-accent-2-A100: #ffffff;
$text-color-accent-2-A100: black;
$color-accent-2-A200: #cefff8;
$text-color-accent-2-A200: black;
$color-accent-2-A400: #9bfff0;
$text-color-accent-2-A400: black;
$color-accent-2-A700: #81ffec;
$text-color-accent-2-A700: black;

$color-gray-50: #f9fbfb;
$text-color-gray-50: black;
$color-gray-100: #f1f5f4;
$text-color-gray-100: black;
$color-gray-200: #cfd5dc;
$text-color-gray-200: black;
$color-gray-300: #dfe9e6;
$text-color-gray-300: black;
$color-gray-400: #b7bdc4;
$text-color-gray-400: black;
$color-gray-500: #99a0a8;
$text-color-gray-500: black;
$color-gray-600: #ccdbd7;
$text-color-gray-600: black;
$color-gray-700: #c6d7d2;
$text-color-gray-700: black;
$color-gray-800: #c0d2cd;
$text-color-gray-800: black;
$color-gray-900: #b5cac4;
$text-color-gray-900: black;
$color-gray-A100: #ffffff;
$text-color-gray-A100: black;
$color-gray-A200: #ffffff;
$text-color-gray-A200: black;
$color-gray-A400: #ffffff;
$text-color-gray-A400: black;
$color-gray-A700: #ffffff;
$text-color-gray-A700: black;

// Missing
$color-app-state--error-100: #fff5f5;
$text-color-app-state--error-100: black;
$color-app-state--error-200: #fed7d7;
$text-color-app-state--error-200: black;
$color-app-state--error-300: #feb2b2;
$text-color-app-state--error-300: black;
$color-app-state--error-400: #fc8181;
$text-color-app-state--error-400: black;
$color-app-state--error-500: #f56565;
$text-color-app-state--error-500: black;
$color-app-state--error-600: #e53e3e;
$text-color-app-state--error-600: black;
$color-app-state--error-700: #c53030;
$text-color-app-state--error-700: white;
$color-app-state--error-800: #9b2c2c;
$text-color-app-state--error-800: white;
$color-app-state--error-900: #742a2a;
$text-color-app-state--error-900: white;

$color-app-state--ok-100: #b8e1d8;
$text-color-app-state--ok-100: black;
$color-app-state--ok-200: #89cdbf;
$text-color-app-state--ok-200: black;
$color-app-state--ok-300: #59b8a5;
$text-color-app-state--ok-300: black;
$color-app-state--ok-400: #36a991;
$text-color-app-state--ok-400: black;
$color-app-state--ok-500: #129a7e;
$text-color-app-state--ok-500: white;
$color-app-state--ok-600: #109276;
$text-color-app-state--ok-600: white;
$color-app-state--ok-700: #0d886b;
$text-color-app-state--ok-700: white;
$color-app-state--ok-800: #0a7e61;
$text-color-app-state--ok-800: white;
$color-app-state--ok-900: #056c4e;
$text-color-app-state--ok-900: white;

// Missing
$color-app-state--warning-100: #fff5f5;
$text-color-app-state--warning-100: black;
$color-app-state--warning-200: #fed7d7;
$text-color-app-state--warning-200: black;
$color-app-state--warning-300: #feb2b2;
$text-color-app-state--warning-300: black;
$color-app-state--warning-400: #fc8181;
$text-color-app-state--warning-400: black;
$color-app-state--warning-500: #f56565;
$text-color-app-state--warning-500: black;
$color-app-state--warning-600: #e53e3e;
$text-color-app-state--warning-600: black;
$color-app-state--warning-700: #c53030;
$text-color-app-state--warning-700: white;
$color-app-state--warning-800: #9b2c2c;
$text-color-app-state--warning-800: white;
$color-app-state--warning-900: #742a2a;
$text-color-app-state--warning-900: white;

:root {
  --font-stack: #{$font-stack};
  --font-stack-mono: #{$font-stack-mono};
  --color-primary-50: #{$color-primary-50};
  --text-color-primary-50: #{$text-color-primary-50};
  --color-primary-100: #{$color-primary-100};
  --text-color-primary-100: #{$text-color-primary-100};
  --color-primary-200: #{$color-primary-200};
  --text-color-primary-200: #{$text-color-primary-200};
  --color-primary-300: #{$color-primary-300};
  --text-color-primary-300: #{$text-color-primary-300};
  --color-primary-400: #{$color-primary-400};
  --text-color-primary-400: #{$text-color-primary-400};
  --color-primary-500: #{$color-primary-500};
  --text-color-primary-500: #{$text-color-primary-500};
  --color-primary-600: #{$color-primary-600};
  --text-color-primary-600: #{$text-color-primary-600};
  --color-primary-700: #{$color-primary-700};
  --text-color-primary-700: #{$text-color-primary-700};
  --color-primary-800: #{$color-primary-800};
  --text-color-primary-800: #{$text-color-primary-800};
  --color-primary-900: #{$color-primary-900};
  --text-color-primary-900: #{$text-color-primary-900};
  --color-primary-A100: #{$color-primary-A100};
  --text-color-primary-A100: #{$text-color-primary-A100};
  --color-primary-A200: #{$color-primary-A200};
  --text-color-primary-A200: #{$text-color-primary-A200};
  --color-primary-A400: #{$color-primary-A400};
  --text-color-primary-A400: #{$text-color-primary-A400};
  --color-primary-A700: #{$color-primary-A700};
  --text-color-primary-A700: #{$text-color-primary-A700};
  --color-secondary-50: #{$color-secondary-50};
  --text-color-secondary-50: #{$text-color-secondary-50};
  --color-secondary-100: #{$color-secondary-100};
  --text-color-secondary-100: #{$text-color-secondary-100};
  --color-secondary-200: #{$color-secondary-200};
  --text-color-secondary-200: #{$text-color-secondary-200};
  --color-secondary-300: #{$color-secondary-300};
  --text-color-secondary-300: #{$text-color-secondary-300};
  --color-secondary-400: #{$color-secondary-400};
  --text-color-secondary-400: #{$text-color-secondary-400};
  --color-secondary-500: #{$color-secondary-500};
  --text-color-secondary-500: #{$text-color-secondary-500};
  --color-secondary-600: #{$color-secondary-600};
  --text-color-secondary-600: #{$text-color-secondary-600};
  --color-secondary-700: #{$color-secondary-700};
  --text-color-secondary-700: #{$text-color-secondary-700};
  --color-secondary-800: #{$color-secondary-800};
  --text-color-secondary-800: #{$text-color-secondary-800};
  --color-secondary-900: #{$color-secondary-900};
  --text-color-secondary-900: #{$text-color-secondary-900};
  --color-secondary-A100: #{$color-secondary-A100};
  --text-color-secondary-A100: #{$text-color-secondary-A100};
  --color-secondary-A200: #{$color-secondary-A200};
  --text-color-secondary-A200: #{$text-color-secondary-A200};
  --color-secondary-A400: #{$color-secondary-A400};
  --text-color-secondary-A400: #{$text-color-secondary-A400};
  --color-secondary-A700: #{$color-secondary-A700};
  --text-color-secondary-A700: #{$text-color-secondary-A700};
  --color-accent-1-50: #{$color-accent-1-50};
  --text-color-accent-1-50: #{$text-color-accent-1-50};
  --color-accent-1-100: #{$color-accent-1-100};
  --text-color-accent-1-100: #{$text-color-accent-1-100};
  --color-accent-1-200: #{$color-accent-1-200};
  --text-color-accent-1-200: #{$text-color-accent-1-200};
  --color-accent-1-300: #{$color-accent-1-300};
  --text-color-accent-1-300: #{$text-color-accent-1-300};
  --color-accent-1-400: #{$color-accent-1-400};
  --text-color-accent-1-400: #{$text-color-accent-1-400};
  --color-accent-1-500: #{$color-accent-1-500};
  --text-color-accent-1-500: #{$text-color-accent-1-500};
  --color-accent-1-600: #{$color-accent-1-600};
  --text-color-accent-1-600: #{$text-color-accent-1-600};
  --color-accent-1-700: #{$color-accent-1-700};
  --text-color-accent-1-700: #{$text-color-accent-1-700};
  --color-accent-1-800: #{$color-accent-1-800};
  --text-color-accent-1-800: #{$text-color-accent-1-800};
  --color-accent-1-900: #{$color-accent-1-900};
  --text-color-accent-1-900: #{$text-color-accent-1-900};
  --color-accent-1-A100: #{$color-accent-1-A100};
  --text-color-accent-1-A100: #{$text-color-accent-1-A100};
  --color-accent-1-A200: #{$color-accent-1-A200};
  --text-color-accent-1-A200: #{$text-color-accent-1-A200};
  --color-accent-1-A400: #{$color-accent-1-A400};
  --text-color-accent-1-A400: #{$text-color-accent-1-A400};
  --color-accent-1-A700: #{$color-accent-1-A700};
  --text-color-accent-1-A700: #{$text-color-accent-1-A700};
  --color-accent-2-50: #{$color-accent-2-50};
  --text-color-accent-2-50: #{$text-color-accent-2-50};
  --color-accent-2-100: #{$color-accent-2-100};
  --text-color-accent-2-100: #{$text-color-accent-2-100};
  --color-accent-2-200: #{$color-accent-2-200};
  --text-color-accent-2-200: #{$text-color-accent-2-200};
  --color-accent-2-300: #{$color-accent-2-300};
  --text-color-accent-2-300: #{$text-color-accent-2-300};
  --color-accent-2-400: #{$color-accent-2-400};
  --text-color-accent-2-400: #{$text-color-accent-2-400};
  --color-accent-2-500: #{$color-accent-2-500};
  --text-color-accent-2-500: #{$text-color-accent-2-500};
  --color-accent-2-600: #{$color-accent-2-600};
  --text-color-accent-2-600: #{$text-color-accent-2-600};
  --color-accent-2-700: #{$color-accent-2-700};
  --text-color-accent-2-700: #{$text-color-accent-2-700};
  --color-accent-2-800: #{$color-accent-2-800};
  --text-color-accent-2-800: #{$text-color-accent-2-800};
  --color-accent-2-900: #{$color-accent-2-900};
  --text-color-accent-2-900: #{$text-color-accent-2-900};
  --color-accent-2-A100: #{$color-accent-2-A100};
  --text-color-accent-2-A100: #{$text-color-accent-2-A100};
  --color-accent-2-A200: #{$color-accent-2-A200};
  --text-color-accent-2-A200: #{$text-color-accent-2-A200};
  --color-accent-2-A400: #{$color-accent-2-A400};
  --text-color-accent-2-A400: #{$text-color-accent-2-A400};
  --color-accent-2-A700: #{$color-accent-2-A700};
  --text-color-accent-2-A700: #{$text-color-accent-2-A700};
  --color-gray-50: #{$color-gray-50};
  --text-color-gray-50: #{$text-color-gray-50};
  --color-gray-100: #{$color-gray-100};
  --text-color-gray-100: #{$text-color-gray-100};
  --color-gray-200: #{$color-gray-200};
  --text-color-gray-200: #{$text-color-gray-200};
  --color-gray-300: #{$color-gray-300};
  --text-color-gray-300: #{$text-color-gray-300};
  --color-gray-400: #{$color-gray-400};
  --text-color-gray-400: #{$text-color-gray-400};
  --color-gray-500: #{$color-gray-500};
  --text-color-gray-500: #{$text-color-gray-500};
  --color-gray-600: #{$color-gray-600};
  --text-color-gray-600: #{$text-color-gray-600};
  --color-gray-700: #{$color-gray-700};
  --text-color-gray-700: #{$text-color-gray-700};
  --color-gray-800: #{$color-gray-800};
  --text-color-gray-800: #{$text-color-gray-800};
  --color-gray-900: #{$color-gray-900};
  --text-color-gray-900: #{$text-color-gray-900};
  --color-gray-A100: #{$color-gray-A100};
  --text-color-gray-A100: #{$text-color-gray-A100};
  --color-gray-A200: #{$color-gray-A200};
  --text-color-gray-A200: #{$text-color-gray-A200};
  --color-gray-A400: #{$color-gray-A400};
  --text-color-gray-A400: #{$text-color-gray-A400};
  --color-gray-A700: #{$color-gray-A700};
  --text-color-gray-A700: #{$text-color-gray-A700};
  --color-app-state--error-100: #{$color-app-state--error-100};
  --text-color-app-state--error-100: #{$text-color-app-state--error-100};
  --color-app-state--error-200: #{$color-app-state--error-200};
  --text-color-app-state--error-200: #{$text-color-app-state--error-200};
  --color-app-state--error-300: #{$color-app-state--error-300};
  --text-color-app-state--error-300: #{$text-color-app-state--error-300};
  --color-app-state--error-400: #{$color-app-state--error-400};
  --text-color-app-state--error-400: #{$text-color-app-state--error-400};
  --color-app-state--error-500: #{$color-app-state--error-500};
  --text-color-app-state--error-500: #{$text-color-app-state--error-500};
  --color-app-state--error-600: #{$color-app-state--error-600};
  --text-color-app-state--error-600: #{$text-color-app-state--error-600};
  --color-app-state--error-700: #{$color-app-state--error-700};
  --text-color-app-state--error-700: #{$text-color-app-state--error-700};
  --color-app-state--error-800: #{$color-app-state--error-800};
  --text-color-app-state--error-800: #{$text-color-app-state--error-800};
  --color-app-state--error-900: #{$color-app-state--error-900};
  --text-color-app-state--error-900: #{$text-color-app-state--error-900};
  --color-app-state--ok-100: #{$color-app-state--ok-100};
  --text-color-app-state--ok-100: #{$text-color-app-state--ok-100};
  --color-app-state--ok-200: #{$color-app-state--ok-200};
  --text-color-app-state--ok-200: #{$text-color-app-state--ok-200};
  --color-app-state--ok-300: #{$color-app-state--ok-300};
  --text-color-app-state--ok-300: #{$text-color-app-state--ok-300};
  --color-app-state--ok-400: #{$color-app-state--ok-400};
  --text-color-app-state--ok-400: #{$text-color-app-state--ok-400};
  --color-app-state--ok-500: #{$color-app-state--ok-500};
  --text-color-app-state--ok-500: #{$text-color-app-state--ok-500};
  --color-app-state--ok-600: #{$color-app-state--ok-600};
  --text-color-app-state--ok-600: #{$text-color-app-state--ok-600};
  --color-app-state--ok-700: #{$color-app-state--ok-700};
  --text-color-app-state--ok-700: #{$text-color-app-state--ok-700};
  --color-app-state--ok-800: #{$color-app-state--ok-800};
  --text-color-app-state--ok-800: #{$text-color-app-state--ok-800};
  --color-app-state--ok-900: #{$color-app-state--ok-900};
  --text-color-app-state--ok-900: #{$text-color-app-state--ok-900};
  --color-app-state--warning-100: #{$color-app-state--warning-100};
  --text-color-app-state--warning-100: #{$text-color-app-state--warning-100};
  --color-app-state--warning-200: #{$color-app-state--warning-200};
  --text-color-app-state--warning-200: #{$text-color-app-state--warning-200};
  --color-app-state--warning-300: #{$color-app-state--warning-300};
  --text-color-app-state--warning-300: #{$text-color-app-state--warning-300};
  --color-app-state--warning-400: #{$color-app-state--warning-400};
  --text-color-app-state--warning-400: #{$text-color-app-state--warning-400};
  --color-app-state--warning-500: #{$color-app-state--warning-500};
  --text-color-app-state--warning-500: #{$text-color-app-state--warning-500};
  --color-app-state--warning-600: #{$color-app-state--warning-600};
  --text-color-app-state--warning-600: #{$text-color-app-state--warning-600};
  --color-app-state--warning-700: #{$color-app-state--warning-700};
  --text-color-app-state--warning-700: #{$text-color-app-state--warning-700};
  --color-app-state--warning-800: #{$color-app-state--warning-800};
  --text-color-app-state--warning-800: #{$text-color-app-state--warning-800};
  --color-app-state--warning-900: #{$color-app-state--warning-900};
  --text-color-app-state--warning-900: #{$text-color-app-state--warning-900};

  --mm-blue: #{$mm-blue};
  --mm-gray-1: #{$mm-gray-1};
  --mm-gray-2: #{$mm-gray-2};
  --mm-gray-3: #{$mm-gray-3};
  --mm-gray-4: #{$mm-gray-4};
}
