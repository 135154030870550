.build-info.build-info--table {
  display: grid;
  gap: 1rem;
  color: var(--color-primary-500);
  padding: 0.5rem 0;

  .copyright {
    font-weight: 600;
    text-align: center;
    color: var(--color-primary-100);
  }

  .authors {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: var(--grid-gap);
    padding-top: 5px;
  }

  dl {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
    font-size: 14px;

    dt,
    dd {
      padding: 0;
      margin: 0;
    }
    dt {
      font-weight: bold;
      text-align: right;
    }
    dt::after {
      content: ": ";
    }
  }
}

.build-info.build-info--single-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 12px;
  color: var(--color-primary-100);
  padding: 0.3rem;

  .copyright {
    font-weight: 600;
    color: var(--color-primary-200);
  }

  dl {
    margin: 0;
    padding: 0;
    display: flex;
    dt,
    dd {
      padding: 0;
      margin: 0;
    }
    dt {
      font-weight: bold;
    }
    dt::after {
      content: ": ";
      padding-right: 0.2rem;
    }
    dt {
      border-left: 2px solid;
      padding-left: 0.2rem;
      margin-left: 0.5rem;
    }
  }
}
