.form-field {
  @extend .grid;
  @extend .grid--center-horizontal;
  @extend .box-section--breakout;

  // this means:
  // - form labels have at least a width of 5rem, which is approx. 80 sofware pixel
  // - However, if the label is longer, let the label take up the space and reduce the width of the input field
  // - max-content will allow labels to take up space without breaking into multiple lines, `min-content` would first
  //   try to break the label into multiple lines before reducing the width of the input field
  grid-template-columns: minmax(max-content, 5.5rem) minmax(0, 1fr);

  color: var(--text-color-gray-50);
  background: var(--color-gray-50);
  padding: 0.2rem 2rem;
  min-height: 36px; // there are other form-fields that are still bigger

  &__ {
    &label {
      display: block;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;

      &__align-top {
        display: flex;
        height: 100%;
        margin-top: 0.96rem;
      }
    }
  }

  &--semantic-link {
    cursor: pointer;
  }

  &--semantic-link:focus-within {
    background: var(--color-accent-2-100);
    color: var(--color-secondary-900);
  }

  :focus-within {
    input {
      border-color: var(--color-secondary-900);
    }
  }

  & .toggle {
    display: flex;
    align-items: center;
  }

  & .disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
  }

  .slide-and-number-input {
    display: grid;
    grid-template-columns: 65% auto;
    gap: 5px;
    justify-self: end;
  }
}

.form-field input:not([type="range"]),
.form-input,
.form-field select,
input:not([type="range"]):not([type="checkbox"]),
select {
  border: 1px solid var(--color-gray-900);
  width: 100%;
  padding: 0.2rem 0.5rem;

  // alignment to right is a better default for numeric input
  &[type="number"] {
    text-align: right;
  }

  // remove default focus outline. see https://stackoverflow.com/questions/3397113/how-to-remove-focus-border-outline-around-text-input-boxes-chrome
  &:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
  }

  &:invalid {
    border-color: var(--color-app-state--error-500);
  }

  // remove autofill background. see https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

input[type="checkbox"]:not(.ag-input-field-input):not(.zeus-switch-container input):not(.mm-checkbox input) {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0.5rem;
}
