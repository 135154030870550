.button-type {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.import-button {
  min-height: 36px;
  cursor: pointer;
}

.hidden-input {
  // TODO Get rid of important flags
  overflow: hidden;
  width: 0px !important;
  height: 0px !important;
  visibility: hidden;
  padding: 0 !important;
  border: 0 !important;
}

.description {
  font-size: 13px;
  margin: 0.2rem 0.2rem;
}

.coords-error {
  color: var(--color-app-state--warning-600);
}

.example-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  font-size: 13px;
  padding: 0;

  span {
    font-size: 18px;
  }
}

.example {
  margin-left: 1.4rem;
  display: grid;
  font-size: 14px;
  color: var(--color-gray-900);
  font-style: italic;
  border-left: 1px solid var(--color-gray-900);
  padding-left: 0.6rem;
}
