// Container for long-form text like blog enries, changelogs, impressum or legal notices, ...
//
// The constructed grid looks as follows:
// ```
// | margin | text-content | margin |
// ```
//
// - text-content has a maximal width.
// - margins take up the reminder of the page width.
// - wide images or tables can be set to take up the margin
//
// @see https://mastery.games/post/article-grid-layout/

:root {
  --text-left-right-margin: 1rem;
  // maximal width of text
  --text-max-width: 80ch;
  // maximal width of text that breaks out of the middle column into the left
  // and right margin. Set to 100% to disable this constraint.
  --text-breakout-max-width: 100%;
}

.text {
  display: grid;
  grid-template-columns:
    minmax(var(--text-left-right-margin), 1fr)
    minmax(auto, var(--text-max-width))
    minmax(var(--text-left-right-margin), 1fr);

  & > * {
    grid-column: 2;
  }

  &--breakout {
    grid-column: 1 / 4;
    width: 100%;
    max-width: var(--text-breakout-max-width);
    justify-self: center;
  }
}
