.toggleable-group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;

  .toggleable {
    input {
      display: none;
    }

    .toggle {
      display: block;
      padding: 0.6rem 0.8rem;
      background: var(--color-gray-50);
      color: var(--text-color-gray-50);
      white-space: nowrap;

      border: 2px solid transparent;

      cursor: pointer;
    }

    input:checked ~ .toggle {
      // background: var(--color-gray-700);
      // color: var(--text-color-gray-700);
      border-bottom: 2px solid var(--color-primary-500);
    }
  }
}
