.background-- {
  &primary {
    background-color: var(--color-gray-500);
    color: var(--color-primary-500);
  }

  &app-error-state {
    background:
      // spotlight on center of window
      radial-gradient(var(--color-app-state--error-200), transparent),
      // weak rim light from top left
      linear-gradient(45deg, var(--color-app-state--error-500), var(--color-app-state--error-900));
    color: var(--text-color-app-state--error-400);
  }

  &accent1-tilted {
    background: linear-gradient(10deg, var(--color-accent-1-500) 9rem, white 9rem);
    color: black;
  }

  &white {
    background: white;
    color: black;
  }
}
