@use "sass:color";

.recording-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 4px solid red;

  z-index: 999;

  .recording-information-row {
    display: flex;
    justify-content: center;

    font-size: 0.9rem;

    & > div {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      padding: 0.4rem 0.9rem;

      background: white;
      align-items: center;

      box-shadow: #00000096 2px 2px 18px;
    }

    .recording-light {
      width: 0.9rem;
      height: 0.9rem;
      background: red;
      border-radius: 1rem;

      animation: blink 2s linear 0s infinite;
    }

    .cancel-recording-button {
      border: 0;
      padding: 0.4rem 1rem;
      border-radius: 1rem;
      color: white;
      background: red;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7rem;

      cursor: pointer;

      &:hover {
        background: color.adjust(red, $blackness: +30%);
      }
    }
  }

  .cancel-recording-panel {
    display: flex;
    justify-content: center;

    position: absolute;
    bottom: 0;
    width: 100%;

    margin-bottom: 2rem;

    .cancel-recording-button {
      border: 0;
      padding: 0.6rem 2rem;
      border-radius: 1rem;

      color: white;
      background: red;

      text-transform: uppercase;
      font-weight: bold;

      cursor: pointer;
    }
  }
}

.video-processing-overlay {
  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  width: 100%;
  position: absolute;

  pointer-events: none;

  & > div {
    display: flex;
    align-items: center;
    gap: 0.4rem;

    background: white;
    font-size: 0.8rem;
    padding: 0.5rem;

    box-shadow: #00000096 2px 2px 18px;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
