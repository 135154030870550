.integer-input {
  display: grid;
  align-items: center;
  grid-template-columns: auto min-content;

  height: 100%;
  padding: 0.2rem;
  grid-gap: 0.2rem;

  &__narrow {
    justify-content: end;
  }

  &__input {
    height: 100%;
    text-align: right;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    & {
      -moz-appearance: textfield;
    }
  }

  &__step-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: transparent;
      color: var(--color-primary-500);
      border: 0;

      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      font-size: 1rem;

      cursor: pointer;

      &:hover:not(:disabled) {
        color: var(--text-color-primary-500);
        background-color: var(--color-primary-500);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &__icon {
        font-size: 1.2rem;
      }
    }
  }
}
