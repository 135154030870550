// TODO: merge this with components/form.scss
// push submit buttons of a form further down
form .button-bar:last-child {
  margin-top: 1rem;
}

.form-group {
  display: grid;
  gap: 0.5rem;
}

.text-input-field {
  // TODO: this styling breaks some click interactions since `input` does not cover
  // the whole visual area. Should put all styles on input and let label layer above it.
  background: rgba(229, 229, 229, 0.9);
  border: 1px solid transparent;
  padding: 0.5rem;
  display: grid;

  &:focus-within {
    border-color: rgba(0, 0, 0, 0.3);
  }

  span {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0.2rem;
    cursor: pointer;
  }

  input {
    font-size: 14px;
    color: black;
    background: transparent;
    border: 0;
    width: 100%;

    // remove default focus outline. see https://stackoverflow.com/questions/3397113/how-to-remove-focus-border-outline-around-text-input-boxes-chrome
    &:focus {
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: 0;
    }
  }

  // remove autofill background. see https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

.text-input-checkbox {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 0.2rem;

  input[type="checkbox"]:not(.ag-input-field-input) {
    margin-left: 4px;
    width: auto;
  }
}
