.profile__list {
  grid-template-columns: 2fr 2fr 2fr 1fr;

  a {
    &.active {
      background: var(--color-primary-500);
      color: var(--text-color-primary-500);
    }
  }

  &.slim {
    grid-template-columns: 1fr;
  }
}

.dashboard__card {
  display: grid;
  background: white;

  & > a:link,
  &--actionable > * {
    text-decoration: none;
    color: inherit;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      background: var(--color-gray-100);
    }
  }

  & > * {
    padding: 1rem 2rem;
    // prevent grid blowout
    overflow: hidden;
  }

  &-slim {
    & > * {
      padding: 0.5rem 1rem;
      // prevent grid blowout
      overflow: hidden;
    }

    & > a:link,
    &--actionable > * {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      grid-gap: 0.5rem;

      text-decoration: none;
      color: inherit;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        background: var(--color-gray-100);
      }
    }

    .date {
      font-size: 0.7rem;
    }
  }
}

.page--profile {
  .gui-overlay {
    pointer-events: none;
  }

  .enable-user-interaction {
    pointer-events: all;
  }
}

.page--profile .box-section.background-img {
  height: 30vh;
  position: relative;
  background-image: url("../clouds_full_size.webp");

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  background-color: rgba(0, 54, 82, 0.61);
  background-blend-mode: multiply;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  padding: 0.5rem;

  & > div {
    margin-left: 1.5rem;
  }

  .intro-watermark {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      color: white;
      font-size: 4rem;
    }
  }

  .text-profile-overview {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    color: white;
  }
}
.page--profile .box-section.background-img.custom-logo-background {
  background-color: rgba(255, 255, 255, 1);
  .intro-watermark {
    h1 {
      color: black;
    }
  }
}
.gui-overlay {
  pointer-events: none;
}

.enable-user-interaction {
  pointer-events: all;
}

.addProfile button.button {
  border: 1px solid var(--color-primary-100);
}
