.button {
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  overflow: visible;
  font-size: 14px;
  font-weight: 500;
  min-height: 36px; // force height if a button is used with an icon only

  &__body {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    gap: 3px;

    // optically center icon, which looks a little bit off
    .material-icons {
      position: relative;
      top: -1px;
    }

    .flip {
      transform: scaleY(-1);
    }
  }

  // contain arrow head
  position: relative;

  &--compact-width {
    min-width: auto;
    padding: 0 7px;
  }

  &--arrow-head--to-left .arrow-head {
    top: 0;
    bottom: 0;
    left: 0;
  }

  &--arrow-head--to-right .arrow-head {
    top: 0;
    bottom: 0;
    right: 0;
  }

  .arrow-head {
    display: block;
    height: 100%;
    position: absolute;

    &__ {
      &backdrop {
        fill: transparent;
      }
      &space-between {
        fill: transparent;
      }
      &tip {
        fill: var(--color-gray-100);
      }
      &bg {
        // Note: this should be parent container background color, but that does note exist in CSS
        fill: white;
      }
    }
  }
}

.icon-button {
  border-radius: 9999rem;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  padding: 0.5rem;
  user-select: none;
  border: 2px solid var(--color-gray-200);
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  box-shadow: -2px 4px 5px 0px rgba(0, 0, 0, 0.05);
}

.button,
.icon-button {
  background: var(--color-gray-100);
  color: var(--text-color-gray-100);
  transition: all 0.2s ease-in-out;

  &--arrow-head--to-left,
  &--arrow-head--to-right {
    transition: none !important;
  }

  // remove default focus outline. see https://stackoverflow.com/questions/3397113/how-to-remove-focus-border-outline-around-text-input-boxes-chrome
  &:focus {
    border-color: var(--color-gray-400);
  }

  // remove autofill background. see https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 1s ease-in-out 0s;
  }

  &:hover {
    background: var(--color-gray-200);
    color: var(--text-color-gray-200);

    .arrow-head .arrow-head__tip {
      fill: var(--color-gray-200);
    }
  }

  &:disabled {
    background: var(--color-gray-50);
    color: var(--text-color-gray-50);
    cursor: default;
  }

  &-- {
    &primary,
    &active {
      background: var(--color-primary-500);
      color: var(--text-color-primary-500);
      &:hover {
        background: var(--color-primary-300);
        color: var(--text-color-primary-300);
      }
    }

    &accept {
      background: var(--color-app-state--ok-500);
      color: var(--text-color-app-state--ok-500);
      &:hover {
        background: var(--color-app-state--ok-300);
        color: var(--text-color-app-state--ok-300);
      }
    }

    &abort {
      background: var(--color-app-state--error-500);
      color: var(--text-color-app-state--error-500);
      &:hover {
        background: var(--color-app-state--error-300);
        color: var(--text-color-app-state--error-300);
      }
    }

    &remove {
      background: var(--color-app-state--error-700);
      color: var(--text-color-primary-300);
      &:hover {
        background: var(--color-app-state--error-300);
        color: var(--text-color-app-state--error-300);
      }
    }

    &illustrated {
      .button__body {
        grid-auto-flow: initial;
      }
      .illustration {
        width: 4rem;
        margin-top: 10px;

        justify-self: center;

        &.tool-icon {
          height: 3.34rem;
        }

        &.table-icon {
          fill-rule: evenodd;
          height: 3.34rem;
        }
      }
    }
  }
}

.button-bar {
  display: grid;
  grid-auto-columns: auto;
  grid-gap: 0.5rem;
  grid-template-rows: 1fr;
  grid-auto-flow: column;

  &-- {
    &align-right {
      justify-content: flex-end;
    }

    &slim {
      .button {
        // reduce button height
        line-height: 2;
      }
    }

    // a group of buttons visually batched into one long button with sections
    &pill {
      border-radius: 6px;
      align-items: stretch;
      gap: 0;

      .button {
        display: grid;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0;
        border-top: 2px solid var(--color-gray-200);
        border-bottom: 2px solid var(--color-gray-200);
        transition: all 0.2s ease-in-out;
        user-select: none;
        border-radius: 0;

        &.button--arrow-head--to-right {
          border-right: 0;

          // provide some space for the absolutly positioned arrow head
          padding-right: 1.3rem;
          padding-left: 0.4rem;

          .arrow-head .arrow-head__space-between {
            fill: var(--color-gray-200);
          }

          .arrow-head .arrow-head__bg {
            fill: var(--color-gray-100);
          }

          // The right-most right-arrowed button: This psedo element was added to fix the arrowhead at the right-most button,
          // but maybe it's better to all buttons' arrowhead with psuedoelements.
          &:first-child {
            border-right: 0;

            &.button--arrow-head--to-right {
              border-right: 0;

              .arrow-head .arrow-head__space-between {
                fill: transparent;
              }

              .arrow-head .arrow-head__bg {
                // For the right-most right-arrowed button, use psuedo element to prevent colored background caused by svg.
                display: none;
              }
            }

            // Psedo element for arrow head for the right-most right-arrowhead button
            &:before,
            &:after {
              width: 1rem;
              height: 50%;
              position: absolute;
              left: 100%;
              content: "";
            }
            &:before {
              top: 0px;
              background: linear-gradient(to right top, var(--color-gray-100) 50%, transparent 50%);
            }
            &:after {
              top: 50%;
              background: linear-gradient(to right bottom, var(--color-gray-100) 50%, transparent 50%);
            }
            &:hover::before {
              background: linear-gradient(to right top, var(--color-gray-200) 50%, transparent 50%);
            }
            &:hover::after {
              background: linear-gradient(to right bottom, var(--color-gray-200) 50%, transparent 50%);
            }
          }
        }

        &.button--arrow-head--to-right:hover + .button--arrow-head--to-right {
          .arrow-head .arrow-head__bg {
            fill: var(--color-gray-200);
          }
        }

        // On hover the left-arrowed button, also highlight the next left-arrowed head button's child SVG
        // in order to highlight the triangle parts on the right side.
        &.button--arrow-head--to-left:hover + .button--arrow-head--to-left {
          .arrow-head .arrow-head__bg {
            fill: var(--color-gray-200);
          }
        }

        &.button--arrow-head--to-left {
          border-right: 0;
          // provide some space for the absolutly positioned arrow head
          padding-left: 1.3rem;
          padding-right: 0.4rem;

          .arrow-head .arrow-head__space-between {
            fill: var(--color-gray-200);
          }

          .arrow-head .arrow-head__bg {
            fill: var(--color-gray-100);
          }

          // The left-most left-arrowed button
          &:first-child {
            &.button--arrow-head--to-left {
              .arrow-head .arrow-head__space-between {
                fill: transparent;
              }

              .arrow-head .arrow-head__bg {
                // For the left-most left-arrowed button, use psuedo element to prevent colored background caused by svg.
                display: none;
              }
            }

            // Psedo element for arrow head for the left-most left-arrowhead button
            &:before,
            &:after {
              width: 1rem;
              height: 50%;
              position: absolute;
              left: -1rem;
              content: "";
            }
            &:before {
              top: 0px;
              background: linear-gradient(to left top, var(--color-gray-100) 50%, transparent 50%);
            }
            &:after {
              top: 50%;
              background: linear-gradient(to left bottom, var(--color-gray-100) 50%, transparent 50%);
            }
            &:hover::before {
              background: linear-gradient(to left top, var(--color-gray-200) 50%, transparent 50%);
            }
            &:hover::after {
              background: linear-gradient(to left bottom, var(--color-gray-200) 50%, transparent 50%);
            }
          }
        }
      }
    }
  }
}
