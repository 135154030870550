.primary {
  fill: #ffffff;
}

.secondary {
  fill: #40e0d0;
}

.black {
  fill: black;
}
