.ens-form {
  display: flex;
  flex-direction: column;

  padding: 0.6rem;
  gap: 0.5rem;
  max-height: 20vh;

  overflow-y: scroll;
}

.ens-container {
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
}

.delimiter {
  border-bottom: 1px solid black;
}

.additional {
  text-transform: capitalize;
}

.ens-checkbox {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2rem auto;
  gap: 0.5rem;
  align-items: center;

  & .ens-input {
    margin: 0;
  }
}
