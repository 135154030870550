.menu {
  display: grid;
  background: var(--color-gray-50);

  gap: 2px;
  padding: 6px;
  min-width: 150px;

  outline: 0;

  &__item {
    display: flex;
    text-align: left;
    align-items: center;

    background-color: var(--color-gray-50);

    font-size: 14px;
    padding: 6px 4px;
    gap: 4px;

    border: 1px solid transparent;
    outline: 0;

    &:hover {
      background-color: var(--color-gray-200);
      color: var(--text-color-gray-200);
    }

    &:focus {
      border: 1px solid var(--color-gray-400);
    }
  }
}
