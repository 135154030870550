.weather-symbol-cell {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
  }
}
