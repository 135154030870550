@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../style/layout/media";

.profiles {
  &--compactSize:first-child {
    padding-top: 0rem;
    gap: 0.5rem 0;
  }

  &--maximizedSize .profiles__list {
    display: grid;

    @include breakpoint($xxsmall) {
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoint($xsmall) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint($small) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint($medium) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include breakpoint($large) {
      grid-template-columns: repeat(5, 1fr);
    }

    @include breakpoint($xlarge) {
      grid-template-columns: repeat(6, 1fr);
    }

    @include breakpoint($xxlarge) {
      grid-template-columns: repeat(7, 1fr);
    }

    @include breakpoint($xxxlarge) {
      grid-template-columns: repeat(8, 1fr);
    }

    grid-template-columns: repeat(9, 1fr);

    gap: 1rem;
    margin: 1rem 0;
  }

  &__heading {
    margin: 0;
    padding: 0;
  }
}

.addProfile--expanded {
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 0.5rem;

  button {
    // no minimal width
    min-width: auto;
  }
}

.card-info {
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  color: var(--color-primary-100);

  &__ {
    &label {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.8em;
      padding-right: 0.5rem;
    }
  }
}

.dashboard-parent__card {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
}

.dashboard__card--maximized {
  &.dashboard__card {
    position: relative;
    display: grid;
    padding: 1rem;
    border-top: 4px solid var(--color-accent-1-500);
    border-left: 1px solid var(--color-primary-100);
    border-right: 1px solid var(--color-primary-100);
    gap: 0.4rem;
    cursor: pointer;

    &:hover {
      background-color: var(--color-gray-50);

      .dashboard__card__actions {
        visibility: visible;
      }
    }

    &--add {
      background: transparent;
      background: var(--color-primary-500);
      color: var(--text-color-primary-500);

      place-content: center;
      text-align: center;

      &:hover {
        background: var(--text-color-primary-500);
        color: var(--color-primary-500);
      }

      .add-icon {
        font-size: 4em;
        padding: 0;
      }
    }

    .dashboard__card__ {
      &title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        text-decoration: none;
        color: inherit;
        padding: 0;
        font-size: 20px;

        &:hover {
          background: transparent;
        }
      }

      &rename-button {
        padding: 0.5rem;
        border: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: transparent;

        cursor: pointer;

        & > span {
          font-size: 1rem;
        }

        &:hover {
          background-color: var(--color-gray-100);
        }
      }

      &current-tab {
        background: var(--color-primary-50);
        color: var(--text-color-primary-50);
        text-decoration: none;
        cursor: pointer;

        padding: 0;
        overflow: auto;
      }

      &metadata {
        padding: 0;
      }

      &actions {
        visibility: hidden;
        display: flex;
        justify-content: flex-end;
        padding: 0;

        &__grid {
          display: grid;
          grid-auto-flow: column;
          grid-gap: 0.5rem;
        }
      }
    }
  }
}

.dashboard__tabs {
  padding: 0;
  display: flex;
  gap: 0.2rem;
  overflow-x: auto;
  border-bottom: 1px solid var(--color-primary-100);
  border-left: 1px solid var(--color-primary-100);
  border-right: 1px solid var(--color-primary-100);

  &__link {
    background: transparent;
    background: var(--color-primary-50);
    color: var(--text-color-primary-50);
    text-decoration: none;
    font-size: 0.8rem;
    line-height: 1rem;
    min-height: 2.4rem; // 2 lines => line-height * 2 + padding-top + padding-bottom
    padding: 0.2rem 0.5rem;
    min-width: 6rem;

    &:hover {
      background: var(--color-primary-500);
      color: var(--text-color-primary-500);
    }
  }
}
