// styling for small colored badges or tags
.tag {
  display: flex;
  align-items: center;
  justify-content: center; /* This centers content horizontally */
  padding: 0.2rem 0.5rem;
  background: var(--color-primary-500);
  border-radius: 999rem;
  font-weight: 200;
  font-size: 10px;
  text-transform: uppercase;
  color: var(--text-color-primary-500);
  letter-spacing: 0.2px;

  &.secondary {
    background: var(--color-accent-2-500);
    color: var(--text-color-primary-50);
  }

  &.tertiary {
    background: var(--color-secondary-50);
    color: black;
  }
}

.tag-param-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid white;
  margin-left: 6px;
}
