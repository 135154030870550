/* Custom properties */
//:root {
//  --tooltip-default-text-color: white;
//  --tooltip-default-background-color: black;
//  --tooltip-margin: 30px;
//  --tooltip-arrow-size: 6px;
//}

/* Wrapping */
.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.tooltip-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: white;
  background: black;
  border-color: black; // Color for the arrow psedo-element.
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.tooltip-tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 6px;
  margin-left: calc(6px * -1);
}

/* Absolute positioning */
.tooltip-tip.top {
  top: calc(10px * -1);
  transform: translateX(-50%) translateY(-100%);
}
/* CSS border triangles */
.tooltip-tip.top::before {
  top: 100%;
  border-top-color: inherit;
}

/* Absolute positioning */
.tooltip-tip.right {
  left: calc(100% + 10px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.tooltip-tip.right::before {
  left: calc(6px * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: inherit;
}

/* Absolute positioning */
.tooltip-tip.bottom {
  bottom: calc(10px * -1);
  transform: translateX(-50%) translateY(100%);
}
/* CSS border triangles */
.tooltip-tip.bottom::before {
  bottom: 100%;
  border-bottom-color: inherit;
}

/* Absolute positioning */
.tooltip-tip.left {
  left: auto;
  right: calc(100% + 10px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.tooltip-tip.left::before {
  left: auto;
  right: calc(6px * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: inherit;
}

/* Absolute positioning */
.tooltip-tip.bottom-left {
  bottom: calc(10px * -1);
  left: auto;
  right: calc(100% - 20px);
  transform: translateX(15%) translateY(100%);
}
/* CSS border triangles */
.tooltip-tip.bottom-left::before {
  bottom: 100%;
  left: auto;
  right: calc(6px * 2);
  transform: translateX(-10%) translateY(0);
  border-bottom-color: inherit;
}

/* Absolute positioning */
.tooltip-tip.top-right {
  top: calc(10px * -1);
  left: calc(100% + 20px);
  transform: translateX(-50%) translateY(-100%);
}
/* CSS border triangles */
.tooltip-tip.top-right::before {
  top: 100%;
  left: calc(6px * 2);
  transform: translateX(-10%) translateY(0);
  border-top-color: inherit;
}
