.no-right-padding {
  padding-right: 0 !important;
}

.point-label {
  padding: 0.2rem 2rem;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}

.poi-input {
  gap: 0.3rem;
}

.successTooltip {
  background: var(--color-app-state--ok-200) !important;
  border-color: var(--color-app-state--ok-400) !important;
  color: var(--color-app-state--ok-900) !important;

  font-size: 0.7rem !important;
}
