.drop-zone {
  position: relative;

  &__ {
    &overlay {
      display: none;

      align-items: center;
      justify-content: center;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: rgba(255, 255, 255, 0.9);

      pointer-events: none;
      z-index: 999;

      & > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;
      }
    }

    &dragging &overlay {
      display: flex;
    }
  }
}
