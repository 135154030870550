/**
 * Library for creating layouts.
 */
:root {
  --grid-gap: 1rem;
}

// Grid layouts. Use as follows:
//
// ```
// <div class="grid" data-columns="1,1"><div>Left</div><div>Right</div></div>
// ```
.grid {
  display: grid;
  gap: var(--grid-gap);
  overflow: hidden;

  &--no-gap {
    gap: 0 !important;
  }

  &--center {
    align-items: center;
    justify-items: center;
  }
  &--center-horizontal {
    align-items: center;
  }
  &--center-vertical {
    justify-items: center;
  }

  &--cover {
    align-items: stretch;
    justify-items: stretch;
  }

  &--4x-1,
  &[data-columns="1,1,1,1"] {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3x-1,
  &[data-columns="1,1,1"] {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2x-1,
  &[data-columns="1,1"] {
    grid-template-columns: repeat(2, 1fr);
  }

  &--flex-1,
  &[data-columns="flex,1"] {
    grid-template-columns: auto 1fr;
  }

  &--1-flex,
  &[data-columns="1,flex"] {
    grid-template-columns: 1fr auto;
  }
}
