.sortOrderSelectorDropdown {
  &__ {
    &desc-asc {
      border-radius: 0;
      border: 1px solid var(--color-gray-900);
      border-left: 0;
    }

    &key {
      border-radius: 0;
    }
  }
}
