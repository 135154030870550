.controls {
  display: flex;
  justify-content: flex-start;

  .control-button {
    border: 0;
    background: transparent;
    font-size: 0.74rem;
    text-decoration: underline;
    text-transform: capitalize;

    cursor: pointer;
  }
}
