.grid-cell-empty {
  display: grid;

  padding: 16px;
  gap: 16px;
  margin: 1em;

  border-radius: 16px;
  background: #73849c91;

  &__button {
    display: flex;
    // width: 124px;
    // TODO: switch or solve when dropdown problem solved
    padding: 0.3rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    height: 112px;
  }

  --button-width: 8rem;
  grid-template-columns: repeat(var(--repeat, auto-fit), 7.5rem);
  
}


@container metx-tool-container (min-width: calc(16rem)){
  .grid-cell-empty{
    --repeat: 2;
  }
}

// Once the container width is bigger than X buttons, set how many grids can fit by `--repeat`
@container metx-tool-container (min-width: calc(24rem)){
  .grid-cell-empty{
    --repeat: 3;
  }
}

@container metx-tool-container (min-width: calc(32rem + 4rem)){
  .grid-cell-empty{
    --repeat: 4;
  }
}

