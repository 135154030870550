@import "../../../style/utils";

@mixin align-button-to-icon($width, $font-size) {
  &:has(:global .material-icons:only-child),
  &:has(:global svg:only-child) {
    justify-content: center;
    padding: 0;
    width: $width;
  }

  & :global .material-icons {
    font-size: $font-size;
  }
}

.rounded-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;

  font-family: var(--font-stack);
  font-size: 0.75rem;

  border: 1px solid var(--mm-gray-1);
  background-color: #ffffff;
  border-radius: 50vh;
  cursor: pointer;

  &:active {
    border: 1px solid #86908d;
  }

  @include figma-font-weight-fix;

  &--small {
    height: 1.5rem;
    padding: 0 0.5rem;

    @include align-button-to-icon(1.5rem, 1rem);
  }

  &--medium {
    height: 1.75rem;
    padding: 0 0.5rem;

    @include align-button-to-icon(1.75rem, 1.25rem);
  }

  &--large {
    height: 2rem;
    padding: 0 0.5rem;

    @include align-button-to-icon(2rem, 1.5rem);
  }

  &--calendar {
    font-size: 0.875rem;
    font-weight: 500;
    height: 2.25rem;
    padding: 0 0.875rem;

    @include align-button-to-icon(2.25rem, 1.25rem);
  }
}
